.App {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form-conrainer {
	padding: 10px;
}
.form-conrainer div {
	display: flex;
	flex-direction: column;
}

.form-conrainer label {
	padding: 5px 0;
}

.btn {
	margin-top: 10px;
}
